import "./src/styles/global.css"

import { timer } from "./src/_lib_/timer"

const videos = [
    {url: "https://d2yva4xedeo1tg.cloudfront.net/videos/11953589_IcySun_mini.mp4", length: 8500},
    {url: "https://d2yva4xedeo1tg.cloudfront.net/videos/12227662_QarimZam_mini.mp4", length: 27500},
    {url: "https://d2yva4xedeo1tg.cloudfront.net/videos/14109154_EdgarVelazquezMictil_mini.mp4", length: 58500},
    // {url: "https://d2yva4xedeo1tg.cloudfront.net/videos/19130256_TatyGkiozos_mini.mp4", length: 1000},
    {url: "https://d2yva4xedeo1tg.cloudfront.net/videos/2711127_Kelly_mini.mp4", length: 20500},
    {url: "https://d2yva4xedeo1tg.cloudfront.net/videos/2888383_Kelly_mini.mp4", length: 24500},
    {url: "https://d2yva4xedeo1tg.cloudfront.net/videos/4127011_KarolinaKaboompics_mini.mp4", length: 19500},
    {url: "https://d2yva4xedeo1tg.cloudfront.net/videos/4867892_MartinaTomšič_mini.mp4", length: 23500}
]

let bgvTag;
let isRunning = false;

async function randomVideoLoop() {
  if (isRunning) {
    return;
  }

  while (true) {
    isRunning = true;

    bgvTag = document.getElementById("background-video");
    if (!bgvTag) {
      continue;
    }

    let nextIdx = Math.floor(videos.length * Math.random());
    let next = videos[nextIdx];

    console.log(next);

    bgvTag.src = videos[Math.floor(videos.length * Math.random())].url;

    await timer(next.length / 2);
  }
}

export const onInitialClientRender = () => {
  randomVideoLoop();
};
